import React from 'react'

import { BlockColumnsSection, Column } from './BlockColumns.styled'

const BlockTextIconBlocks = data => {
	return (
		<BlockColumnsSection paddingTop={data.paddingTop} paddingBottom={data.paddingBottom}>
			<Column dangerouslySetInnerHTML={{ __html: data.column1 }} />
			<Column dangerouslySetInnerHTML={{ __html: data.column2 }} />
		</BlockColumnsSection>
	)
}

export default BlockTextIconBlocks
