import styled from 'styled-components'

import Section from '../../Section'

const BlockColumnsSection = styled(Section)`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		flex-direction: row;
	}
`

const Column = styled.div`
	width: 100%;

	p {
		margin-bottom: 15px;
	}

	strong {
		font-size: 20px;
	}

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: auto;
	}

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		width: auto;
	}
`

export { BlockColumnsSection, Column }
